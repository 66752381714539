import { ConnectedRouter } from 'connected-react-router';
import { throttle } from 'lodash-es';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import type { History } from 'history';
import type { ConnectedProps } from 'react-redux';

import { toggleMobileMode } from './actions/mobileActions';
import ApiConfig from './api/ApiConfig';
import AttachmentsLightbox from './Components/Case/AttachmetsLightbox/AttachmentsLightbox';
import { CommentsContainerContextProvider } from './Components/Case/CommentsContainerContext';
import MissingADAccount from './Components/General/MissingADAccount';
import LoaderComponent from './Components/Loader/Loader';
import WhatsappCallbackPage from './Components/Management/Whatsapp/WhatsappCallbackPage';
import DisconnectedNotification from './Components/Notifications/DisconnectedNotification';
import activationContainer from './containers/activationContainer';
import CaseContainer from './containers/CaseContainer';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import Main from './containers/MainContainer';
import ErrorBoundary from './ErrorBoundary';
import OutdatedVersionModal from './OutdatedVersionModal';
import { PrivateRoute } from './PrivateRoute';
import { refreshToken } from 'src/actions/authActions';
import FeatureFlags from 'src/api/FeatureFlags';
import GlobalModal from 'src/Components/Modal/GlobalModal';
import { ModalProvider } from 'src/Components/Modal/ModalContext';
import LoginComponent from 'src/LoginComponent';
import { store } from 'src/store';

import type { State } from 'src/types/initialState';

import 'src/App.css';

const AutomaticClosingTickets = React.lazy(() => import('src/Components/AutomaticClosingTickets'));
const FloatingWindows = React.lazy(
  () => import('src/Components/Case/InfopageCase/Widget/SuggestedArticles/FloatingWindows/FloatingWindows')
);
const ShortcutsModal = React.lazy(() => import('src/Components/Shortcuts/ShortcutsModal'));

interface AppProps extends ConnectedProps<typeof connector> {
  history: History;
}

class App extends Component<AppProps> {
  componentDidMount() {
    this.resizeListener();
    window.addEventListener('resize', this.resizeListener);

    /**
     * Use case:
     * After device innactivity user returns to the page and 'visibilitychange' event fires
     * During that time no timeouts were fired nor renewed
     */
    document.addEventListener('visibilitychange', function () {
      if (
        document.visibilityState === 'visible' &&
        window.refreshTimeout === undefined &&
        localStorage.getItem('loggedIn') === 'true'
      ) {
        store.dispatch(refreshToken(true) as any);
      }
    });

    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        console.info('Notification permission:', permission);
      });
    }

    if (ApiConfig.getConfig().OVERRIDE_TITLE) {
      document.title = ApiConfig.getConfig().OVERRIDE_TITLE as string;
    }

    if (ApiConfig.getConfig().OVERRIDE_FAVICON) {
      const faviconElements = document.querySelectorAll<HTMLLinkElement>('link[rel*="icon"]');
      faviconElements.forEach((faviconElement) => {
        faviconElement.href = ApiConfig.getConfig().OVERRIDE_FAVICON as string;
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  private resizeListener = throttle(() => {
    if (window.innerWidth < 800) {
      this.props.toggleMobileMode(true);
    } else {
      this.props.toggleMobileMode(false);
    }
  }, 500);

  render() {
    const showFloatingWindows =
      (FeatureFlags.isFlagOn('ENABLE_FLOATING_WINDOW') || FeatureFlags.isFlagOn('ENABLE_AI_ASSISTANT')) &&
      this.props.auth &&
      !this.props.isLoading;

    return (
      <ErrorBoundary>
        <ConnectedRouter history={this.props.history}>
          <CommentsContainerContextProvider>
            <ModalProvider>
              <GlobalModal />
              <>
                <Switch>
                  <Route path="/login" component={LoginComponent} />
                  <Route path="/missingADAccount" component={MissingADAccount} />
                  <Route path="/activate" component={activationContainer} />
                  <Route path="/forgotpassword" component={ForgotPasswordContainer} />
                  {FeatureFlags.isFlagOn('WHATSAPP_ENABLED') && (
                    <PrivateRoute
                      path="/whatsapp/callback"
                      authenticated={this.props.auth}
                      component={WhatsappCallbackPage}
                    />
                  )}
                  <PrivateRoute
                    authenticated={this.props.auth}
                    path="/caseview/:id"
                    component={CaseContainer}
                    exact={true}
                  />
                  <PrivateRoute path={'/'} authenticated={this.props.auth} component={Main} />
                </Switch>
              </>
            </ModalProvider>
          </CommentsContainerContextProvider>
        </ConnectedRouter>
        <OutdatedVersionModal />
        <DisconnectedNotification />
        <AttachmentsLightbox />

        <Suspense fallback={<div>VSJO PLOHO</div>}>
          <ShortcutsModal />
          {FeatureFlags.isFlagOn('AUTOMATIC_CLOSING_TICKETS') && <AutomaticClosingTickets />}
          {showFloatingWindows && (
            <Suspense fallback={<LoaderComponent />}>
              <FloatingWindows />
            </Suspense>
          )}
        </Suspense>
      </ErrorBoundary>
    );
  }
}

const connector = connect(
  (state: State) => ({
    auth: state.auth.loggedIn,
    isLoading: state.initialRequests.some((r) => r.isLoading || !r.isCompleted)
  }),
  { toggleMobileMode }
);

export default connector(App);
