import { Direction } from '@eeedo/types';
import {
  faChevronDown,
  faChevronUp,
  faComments,
  faRotateRight,
  faSlidersSimple
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import EventsToggle from '../EventsToggle';
import ImagesToggle from '../ImagesToggle';
import SingleLineCommentsToggle from '../SingleLineCommentsToggle';
import styles from './DiscussionTopBar.module.css';
import { clearCommentFilter } from 'src/actions/commentFiltersActions';
import FeatureFlags from 'src/api/FeatureFlags';
import Button from 'src/Components/generic/Button/Button';
import Label from 'src/Components/generic/Label/Label';
import { CommentsMenuItem } from 'src/types/Comments';

import type { CommentsTab } from 'src/types/Comments';
import type { State } from 'src/types/initialState';

interface CommentsMenuProps {
  caseId: string;
  direction: Direction;
  mobileMode: boolean;
  showedCommentsLength: number;

  onDirectionClick(): void;
  setCommentFiltersModalOpen: (state: boolean) => void;
}

export const DiscussionTopBar: FC<CommentsMenuProps> = ({
  caseId,
  mobileMode,
  direction,
  showedCommentsLength,

  onDirectionClick,
  setCommentFiltersModalOpen
}) => {
  const { t } = useTranslation();
  const commentFilters = useSelector((state: State) => state.commentFilters[state.activeTicketTab!]);
  const dispatch = useDispatch();

  const onClickClear = () => {
    dispatch(clearCommentFilter(caseId));
  };

  const menuTab = {
    id: 'discussionTab',
    title: t('CASE_DISCUSSION'),
    key: CommentsMenuItem.messages,
    name: 'comments'
  } as CommentsTab;

  const showImagesToggle = FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES_TOGGLE');

  return (
    <div className={styles.discussionTopBarContainer}>
      <FontAwesomeIcon icon={faComments} size="2x" style={{ width: '24px', height: '24px' }} />
      <div className={styles.title}>{mobileMode || menuTab.title}</div>
      <Label color="blue">{showedCommentsLength}</Label>

      <div className={styles.gap} />

      <SingleLineCommentsToggle />

      <Button
        icon={<FontAwesomeIcon icon={direction === Direction.ASC ? faChevronUp : faChevronDown} />}
        onClick={onDirectionClick}
        content={t('comments.buttons.sort_by_date')}
      />

      {commentFilters && !!Object.keys(commentFilters).length && (
        <Popup
          inverted
          size="tiny"
          content={t('filter.clear')}
          trigger={
            <Button
              type="error"
              icon={<FontAwesomeIcon icon={faRotateRight} />}
              onClick={onClickClear}
              content={t('filter.clear')}
            />
          }
        />
      )}
      <Button onClick={() => setCommentFiltersModalOpen(true)} icon={<FontAwesomeIcon icon={faSlidersSimple} />} />

      {showImagesToggle && <ImagesToggle />}

      <EventsToggle />
    </div>
  );
};

export default DiscussionTopBar;
