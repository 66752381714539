import { faForward, faRedo, faReply, faReplyAll } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../generic/Button/Button';
import CommentWidgetButton from './CommentWidgetButton';
import * as styles from './CommentWidgetButtons.style';

export interface CommentWidgetButtonsProps {
  buttonsType?: 'basic' | 'primary';

  onReplyClick: () => void;
  onReplyAllClick?: () => void;
  onReplyForwardClick?: () => void;
  onResendClick?: () => void;
}

export const CommentWidgetButtons = ({
  buttonsType,
  onReplyClick,
  onReplyAllClick,
  onReplyForwardClick,
  onResendClick
}: CommentWidgetButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div style={styles.container}>
      <Button
        iconLeft={<FontAwesomeIcon icon={faReply} />}
        content={t('COMMENT_MAIL_REPLY')}
        type={buttonsType === 'primary' ? 'primary' : 'normal'}
        onClick={onReplyClick}
      />

      {!!onReplyAllClick && (
        <CommentWidgetButton
          icon={<FontAwesomeIcon icon={faReplyAll} />}
          text={t('COMMENT_MAIL_REPLY_ALL')}
          buttonsType={buttonsType}
          onClick={onReplyAllClick}
        />
      )}

      {!!onReplyForwardClick && (
        <CommentWidgetButton
          icon={<FontAwesomeIcon icon={faForward} />}
          text={t('COMMENT_MAIL_FORWARD')}
          buttonsType={buttonsType}
          onClick={onReplyForwardClick}
        />
      )}

      {!!onResendClick && (
        <CommentWidgetButton
          icon={<FontAwesomeIcon icon={faRedo} />}
          text={t('COMMENT_MAIL_RESEND')}
          buttonsType={buttonsType}
          onClick={onResendClick}
        />
      )}
    </div>
  );
};
