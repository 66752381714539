import React from 'react';
import { Popup } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';

import Button from './Button/Button';

interface ReplyControlButtonProps extends ComponentProps<typeof Button> {
  className?: string;
  shrink?: boolean;
}

const ShrinkableButton: FC<ReplyControlButtonProps> = ({ children, icon, shrink, ...buttonProps }) => {
  const button = (
    <Button {...{ [shrink ? 'icon' : 'iconLeft']: icon }} {...buttonProps}>
      {!shrink && children}
    </Button>
  );

  return shrink ? <Popup trigger={button} content={children} /> : button;
};

export default React.memo(ShrinkableButton);
