import React from 'react';
import { Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from '../Button/Button';

interface PasswordShowHideButtonProps {
  type?: string;
  inputType: string;
  onClick: (type: string) => void;
}

interface RenderIconProps {
  inputType: string;
}

const PasswordShowHideButton: FC<PasswordShowHideButtonProps> = React.memo(({ type = 'text', inputType, onClick }) => {
  if (type !== 'password') return null;
  return (
    <Button
      center
      htmlType="button"
      content={<RenderIcon inputType={inputType} />}
      onClick={() => onClick(inputType === 'text' ? 'password' : 'text')}
    />
  );
});

const RenderIcon: FC<RenderIconProps> = React.memo(({ inputType }) => {
  const iconName = inputType === 'text' ? 'eye slash' : 'eye';
  return <Icon name={iconName} />;
});

export default PasswordShowHideButton;
