import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { throttle } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dropdown, Form } from 'semantic-ui-react';

import { clearCommentFilter, setCommentFilter } from 'src/actions/commentFiltersActions';
import DateSelector, { timePrefix } from 'src/Components/Case/DateSelector';
import { ArrayDropdownFilter } from 'src/Components/Filter/DropdownFilter';
import Button from 'src/Components/generic/Button/Button';
import Input from 'src/Components/generic/Input/Input';
import Label from 'src/Components/generic/Label/Label';
import Modal from 'src/Components/generic/Modal/Modal';
import Toggle from 'src/Components/generic/Toggle/Toggle';
import { useAppThunkDispatch } from 'src/store';

import type { State } from 'src/types/initialState';
import type { CommentFilters } from 'src/types/Ticket';

interface CommentFiltersModal {
  caseId: string;
  commentAuthors: string[];
  showedCommentsLength: number;
  totalCommentsAmount: number;

  setCommentFiltersModalOpen: (state: boolean) => void;
}

const CommentFiltersModal = ({
  caseId,
  commentAuthors,
  showedCommentsLength,
  totalCommentsAmount,

  setCommentFiltersModalOpen
}: CommentFiltersModal) => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const channels = useSelector((state: State) => state.channels);
  const commentFilters = useSelector((state: State) => state.commentFilters[state.activeTicketTab!] || {});

  const setFilterValue = (property: keyof CommentFilters, value: unknown) =>
    dispatch(
      setCommentFilter({
        caseId,
        property,
        value
      })
    );

  const throttledSetFilterValue = throttle((property: keyof CommentFilters, value: string) => {
    setFilterValue(property, value);
  }, 2000);

  const getModalHeader = () => {
    return (
      <>
        <span>{t('comments.titles.comment_filters')}</span>

        <Label color="green" size="small" style={{ marginLeft: '5px', pointerEvents: 'none' }}>
          {t('comments.labels.showing_comments', {
            showedCommentsLength,
            totalCommentsAmount
          })}
        </Label>

        <Button
          icon
          type="error"
          iconRight={<FontAwesomeIcon icon={faXmark} />}
          style={{ marginLeft: 'auto' }}
          onClick={() => dispatch(clearCommentFilter(caseId))}
        >
          {t('filter.clear')}
        </Button>
      </>
    );
  };

  return (
    <Modal
      open
      size="small"
      centered={false}
      closeOnEscape
      closeOnDimmerClick
      closeOnDocumentClick
      className="commentFiltersModal"
      onClose={() => setCommentFiltersModalOpen(false)}
      header={getModalHeader()}
    >
      <Form.Field className="col2">
        <label>{t('comments.labels.search_by_text')}</label>
        <Input
          fluid
          icon="search"
          placeholder={t('comments.labels.search_through_content')}
          value={commentFilters.text || ''}
          onChange={(_, data) => throttledSetFilterValue('text', data.value)}
        />
      </Form.Field>

      <Form.Field className="col2">
        <label>{t('GENERAL_DIRECTION')}</label>
        <Dropdown
          options={[
            {
              text: t('DIRECTION_IN'),
              value: 'in'
            },
            {
              text: t('DIRECTION_OUT'),
              value: 'out'
            }
          ]}
          fluid
          search
          selection
          scrolling
          clearable
          selectOnBlur={false}
          placeholder={t('GENERAL_DIRECTION')}
          value={commentFilters.direction || ''}
          onChange={(_, data) => setFilterValue('direction', String(data.value))}
        />
      </Form.Field>

      <Form.Field className="col2">
        <label>{t('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS')}</label>
        <ArrayDropdownFilter
          placeholder={t('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS')}
          value={commentFilters.channels}
          onChange={(value) => setFilterValue('channels', value)}
          options={channels.map((channel) => ({ text: channel.channel, value: channel.id }))}
        />
      </Form.Field>

      <Form.Field className="col2">
        <label>{t('GENERAL_AUTHOR')}</label>
        <ArrayDropdownFilter
          placeholder={t('GENERAL_AUTHOR')}
          value={commentFilters.author}
          onChange={(value) => setFilterValue('author', value)}
          options={commentAuthors.map((author) => ({ text: author, value: author }))}
        />
      </Form.Field>

      <Form.Field>
        <label>{t('comments.labels.date_creation_range')}</label>
        <div style={{ display: 'flex', gap: '10px' }}>
          <DateSelector
            value={commentFilters.createdAfter}
            onChange={(timestamp) => setFilterValue('createdAfter', timestamp?.replace(timePrefix, ''))}
          />

          <DateSelector
            value={commentFilters.createdBefore}
            onChange={(timestamp) => setFilterValue('createdBefore', timestamp?.replace(timePrefix, ''))}
          />
        </div>
      </Form.Field>

      <Form.Field className="col2">
        <label>{t('comments.labels.has_attachments')}</label>
        <Toggle
          checked={commentFilters.hasAttachment || false}
          onChange={(_, data) => setFilterValue('hasAttachment', data.checked)}
        />
      </Form.Field>
    </Modal>
  );
};

export default CommentFiltersModal;
