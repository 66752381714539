import iziToast from 'izitoast';
import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Icon, Label, Popup } from 'semantic-ui-react';

import type { Channel, EmailSendingStatus, SenderEmail, TicketType } from '@eeedo/types';
import type { DropdownItemProps, SemanticCOLORS } from 'semantic-ui-react';

import { WhatsAppCommentButtons } from '../CommentEditorWidget/WhatsAppCommentButtons';
import CommentIcon from '../CommentIcon/CommentIcon';
import ChannelType from '../CommentIconContent/ChannelType';
import CommentIconContent from '../CommentIconContent/CommentIconContent';
import HeaderEmailPreview from '../CommentIconContent/HeaderEmailPreview';
import CopyToClipboardIcon from '../generic/CopyToClipboardIcon';
import { convertPrefixStringToNumber } from '../Utilities';
import { appendToDraft, switchDraftTabIndex } from 'src/actions/draftActions';
import { generateTags, generateTicketRelevantInfo } from 'src/actions/openAIActions';
import AnonymizationApi from 'src/api/AnonymizationApi';
import FeatureFlags from 'src/api/FeatureFlags';
import { getCommentAttachmentsIds } from 'src/Components/Utilities/comments';
import CommentEditorWidgetContainer from 'src/containers/CommentEditorWidgetContainer';
import SuggestionEditorWidgetContainer from 'src/containers/SuggestionEditorWidgetContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { useAppThunkDispatch } from 'src/store';
import { Channels } from 'src/types/Channel';
import { Roles } from 'src/types/User';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { convertCaseNaming } from 'src/Utilities/helper';
import { parseContent } from 'src/Utilities/parseUtils';
import { sanitizeHTML } from 'src/Utilities/sanitize';

import type { State } from 'src/types/initialState';
import type { Ticket, Comment as TicketComment } from 'src/types/Ticket';

interface CommentMetadataProps {
  task: Ticket;
  ticketTypes: TicketType[];
  comment: Partial<TicketComment>;
  hasHTMLMetadata: boolean;
  isIntegratorUserReply: boolean;
  channelData: Channel | undefined;
  htmlMetaData: string;
  channels: Channel[];
  channelTypeName: string;
  replyToEmailEnabled: boolean;
  senderEmails: SenderEmail[];

  setMetadataSourceOpen: () => void;
}

const CommentMetadata = ({
  hasHTMLMetadata,
  task,
  comment,
  channelData,
  ticketTypes,
  isIntegratorUserReply,
  htmlMetaData,
  channels,
  channelTypeName,
  replyToEmailEnabled,
  senderEmails,
  setMetadataSourceOpen
}: CommentMetadataProps) => {
  const dispatch = useDispatch();
  const dispatchThunk = useAppThunkDispatch();
  const { t } = useTranslation();
  const user = useSelector(
    (state: State) => state.usersList.usersList.find((user) => user.UID === state.userData.UID)!
  );
  const commentContent = hasHTMLMetadata ? htmlMetaData : comment.content!;

  /**
   * modifications added for preview email
   * if there is an image in the core of the email, it skips it
   * cid : signature image / embed image too - in order to avoid bug
   * note : - IMAGE_UPLOAD_VIA_EDITOR this flag was off, if there is a loading uploading an image, try to click outside
   *        - attachment is not affected
   * @param html
   * @param isHtml
   */
  const openHTMLMetaData = (html: string, isHtml: boolean) => {
    const newHtml = parseContent(html, task.attachments, !isHtml);
    const sanitizedHTML = sanitizeHTML(newHtml);
    const htmlTab = window.open('about:blank', '_blank');

    htmlTab?.document.write(sanitizedHTML);
    htmlTab?.focus();
  };

  /**
   *  funtion return a string for Preview email called by openHTMLMetaData()
   *   @date : number
   *   @return {string} html to concatenate for openHTMLMetaData()
   * */
  const metaDataEmail = (date: number): string => {
    const datePreview = renderToString(
      <b>
        {getPrettyDate(date, { format: DATE_TIME_FORMAT })}
        <hr />
      </b>
    );
    const message = renderToString(
      <>
        <HeaderEmailPreview
          channels={channels}
          ticketTypes={ticketTypes}
          channel={comment.channel!}
          title={comment.title || null}
          metaData={comment.metaData!}
          created={getPrettyDate(comment.created, { format: DATE_TIME_FORMAT })}
        />
        <br />
      </>
    );

    return `${datePreview}${message}`;
  };

  const setWhatsAppDraft = () => {
    const reply = `\n\n*RE:* _${comment.content}_`;
    dispatch(appendToDraft({ content: reply }, task.id, Channels.whatsapp));
    dispatch(switchDraftTabIndex(task.id, ChannelType.WhatsApp));
  };

  const moreOptions = useMemo(() => {
    const options: {
      key: string;
      text: string;
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: DropdownItemProps) => void;
    }[] = [];

    if (comment.direction === 'in' && FeatureFlags.isFlagOn('ENABLE_OPENAI')) {
      options.push(
        {
          key: 'rel_info',
          text: t('widgets.chatGPT.buttons.rel_info'),
          onClick: async () => {
            await dispatchThunk(
              generateTicketRelevantInfo({
                contentId: convertCaseNaming(task.id, 'number', 'task') as number,
                content: comment.content || '',
                isHtml: !!comment.metaData?.html,
                commentId: parseInt(comment.id!.substring(3), 10)
              })
            );
          }
        },
        {
          key: 'generate_tags',
          text: t('widgets.chatGPT.buttons.generate_tags'),
          onClick: async () => {
            await dispatchThunk(
              generateTags({
                contentId: convertCaseNaming(task.id, 'number', 'task') as number,
                content: comment.content || '',
                isHtml: !!comment.metaData?.html,
                commentId: parseInt(comment.id!.substring(3), 10)
              })
            );
          }
        }
      );
    }

    if (FeatureFlags.isFlagOn('MANUALLY_ANONYMIZE_COMMENT') && Roles.isAdmin(user.role.id)) {
      options.push({
        key: 'comment_anonymization',
        text: t('anonymization.labels.comment_anonymization'),
        onClick: async () => {
          try {
            await AnonymizationApi.anonymize({ commentIds: [convertPrefixStringToNumber(comment.id!, 'COM')] });
            iziToast.success({
              message: t('anonymization.toasts.comment_success'),
              icon: 'icon check'
            });
          } catch (error) {
            iziToast.error({
              message: t('anonymization.toasts.comment_fail'),
              timeout: 3000,
              position: 'bottomRight'
            });
          }
        }
      });
    }

    return options;
  }, [t, comment, dispatchThunk, task.id, user.role.id]);

  const renderSendingStatus = (sendingStatus: EmailSendingStatus) => {
    const statusMapping = {
      pending: {
        color: 'blue',
        text: t('PENDING')
      },
      failed: {
        color: 'red',
        text: t('FAILED')
      },
      success: {
        color: 'green',
        text: t('SUCCESS')
      }
    };
    return (
      <Popup
        content={t('EMAIL_SENDING_STATUS')}
        trigger={
          <Label size="small" color={statusMapping[sendingStatus].color as SemanticCOLORS} horizontal>
            {statusMapping[sendingStatus].text}
          </Label>
        }
      />
    );
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '4px' }}>
      <div className={channelTypeName || 'other CommentIcon'}>
        <Popup
          className="commentContentPopup"
          position="top center"
          trigger={
            <i>
              <CommentIcon
                style={{
                  color: channelData?.color || ''
                }}
                channel={channelData}
                originalDirection={comment.direction!}
              />
            </i>
          }
          content={
            <ErrorBoundary>
              <CommentIconContent
                channels={channels}
                ticketTypes={ticketTypes}
                channel={comment.channel!}
                title={comment.title || null}
                metaData={comment.metaData!}
                showSender
              />
            </ErrorBoundary>
          }
          on={'hover'}
          hoverable={true}
        />
      </div>

      {/* TODO: maybe should allow to copy always? */}
      {!isIntegratorUserReply && (
        <CopyToClipboardIcon
          text={comment.textOnly ?? comment.content!}
          className="icon-medium"
          style={{ opacity: 1 }}
        />
      )}

      {(hasHTMLMetadata || comment.content) &&
        comment.type === 'normal' &&
        FeatureFlags.isFlagOn('ENABLE_COMMENT_HTML') && (
          <div className={channelTypeName || 'other CommentIcon'}>
            <span
              title={t('PRINT')}
              className="hoverClick"
              onClick={() => {
                openHTMLMetaData(metaDataEmail(comment.created!) + commentContent, hasHTMLMetadata);
              }}
            >
              <i>
                <Icon
                  className={'icon-medium'}
                  style={{
                    color: (channelData && channelData.color) || ''
                  }}
                  name={'file code'}
                />
              </i>
            </span>
          </div>
        )}

      {comment.channel === ChannelType.Email && comment.metaData && (
        <i onClick={setMetadataSourceOpen}>
          <Icon
            className={'icon-medium'}
            style={{
              color: (channelData && channelData.color) || '',
              cursor: 'pointer'
            }}
            name="info circle"
          />
        </i>
      )}

      {/* TODO : have to change the icon of webform />*/}
      {comment.channel === ChannelType.Webform && replyToEmailEnabled && (
        <div className="Comment-Content images-max-w-95">
          <ErrorBoundary>
            <CommentEditorWidgetContainer
              senderEmails={senderEmails}
              created={comment.created!}
              title={comment.title || ''}
              comment={comment.content!}
              taskId={task.id}
              metaData={comment.metaData}
              isHTML={false}
              attachmentIds={getCommentAttachmentsIds(comment.metaData, task)}
            />
          </ErrorBoundary>
        </div>
      )}

      <WhatsAppCommentButtons channel={comment.channel!} onReplyClick={setWhatsAppDraft} />

      {comment.type === 'suggestion' && (
        <div className="Comment-Content images-max-w-95">
          <ErrorBoundary>
            <SuggestionEditorWidgetContainer
              channel={comment.channel!}
              senderEmails={senderEmails}
              created={comment.created!}
              title={comment.title || ''}
              comment={comment.content!}
              taskId={task.id}
              metaData={comment.metaData}
              isHTML={false}
              attachmentIds={getCommentAttachmentsIds(comment.metaData, task)}
            />
          </ErrorBoundary>
        </div>
      )}

      {comment.metaData?.sendingStatus && (
        <div className="Comment-Content images-max-w-95">{renderSendingStatus(comment.metaData.sendingStatus)}</div>
      )}

      {moreOptions.length ? (
        <Dropdown icon="ellipsis horizontal" direction="left">
          <Dropdown.Menu>
            {moreOptions.map(({ key, text, onClick }) => (
              <Dropdown.Item text={text} onClick={onClick} key={key} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}

      <div style={{ marginLeft: '24px' }}>{getPrettyDate(comment.created, { format: DATE_TIME_FORMAT })}</div>
    </div>
  );
};

export default CommentMetadata;
