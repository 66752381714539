import React, { createContext, useState } from 'react';

import type { MailIntegration } from '@eeedo/types';
import type { ReactNode } from 'react';

export type MailIntegrationContextType = {
  integrations: MailIntegration[];
  setIntegrations: React.Dispatch<React.SetStateAction<MailIntegration[]>>;
};

export const MailIntegrationContext = createContext<MailIntegrationContextType | undefined>(undefined);

export const MailIntegrationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [integrations, setIntegrations] = useState<MailIntegration[]>([]);

  return (
    <MailIntegrationContext.Provider value={{ integrations, setIntegrations }}>
      {children}
    </MailIntegrationContext.Provider>
  );
};
