import React, { useContext } from 'react';

import type { CSSProperties, FC, ReactNode } from 'react';
import type { ButtonProps } from 'semantic-ui-react';

import { CommentsContainerContext } from '../Case/CommentsContainerContext';
import ShrinkableButton from '../generic/ShrinkableButton';

import type { CommentWidgetButtonsProps } from './CommentWidgetButtons';

interface CommentWidgetButtonProps extends Pick<CommentWidgetButtonsProps, 'buttonsType'> {
  text: string;
  icon: ReactNode;
  style?: CSSProperties;
  className?: string;

  onClick: ButtonProps['onClick'];
}

const CommentWidgetButton: FC<CommentWidgetButtonProps> = ({ text, icon, className, buttonsType, style, onClick }) => {
  const { shouldShrink } = useContext(CommentsContainerContext);

  return (
    <ShrinkableButton
      size="sm"
      style={style}
      className={className}
      icon={icon}
      shrink={shouldShrink}
      onClick={onClick}
      {...(buttonsType && {
        [buttonsType]: true
      })}
    >
      {text}
    </ShrinkableButton>
  );
};

export default React.memo(CommentWidgetButton);
