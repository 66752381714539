import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Ref, Button as SemanticButton } from 'semantic-ui-react';

import type { CSSProperties, FC, ReactNode } from 'react';
import type { ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

import './Button.css';

type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonType = 'primary' | 'normal' | 'error' | 'positive';

export interface ButtonProps {
  id?: string;
  as?: SemanticButtonProps['as'];
  disabled?: boolean;
  content?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
  htmlType?: SemanticButtonProps['type'];
  fullWidth?: boolean;
  borderless?: boolean;
  style?: CSSProperties;
  icon?: React.ReactNode;
  center?: boolean;
  children?: ReactNode;

  onWidthChange?: (width: number) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: SemanticButtonProps) => void;
}

const Button: FC<ButtonProps> = ({
  id,
  as,
  disabled,
  content,
  iconLeft,
  iconRight,
  size = 'md',
  type = 'normal',
  htmlType,
  fullWidth = false,
  borderless = false,
  icon,
  center,
  style,
  children,

  onClick,
  onWidthChange
}: ButtonProps) => {
  const buttonClass = classNames('eeedoButton', `size__${size}`, `type__${type}`, {
    iconOnly: !!icon,
    center: !!center,
    borderless: !!borderless,
    fullWidth: !!fullWidth
  });
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current && onWidthChange) {
        const width = ref.current.offsetWidth;
        onWidthChange(width);
      }
    };
    updateWidth();

    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [onWidthChange, content, iconLeft, iconRight]);

  return (
    <Ref innerRef={ref}>
      <SemanticButton
        id={id}
        as={as}
        style={style}
        className={buttonClass}
        disabled={disabled}
        type={htmlType}
        onClick={onClick}
      >
        {iconLeft && <span className="icon-wrapper">{iconLeft}</span>}
        {(content || children) && <span className="content-wrapper">{content ?? children}</span>}
        {icon && <span className="icon-wrapper">{icon}</span>}
        {iconRight && <span className="icon-wrapper">{iconRight}</span>}
      </SemanticButton>
    </Ref>
  );
};

export default Button;
